import axios from "axios";
import Element, { Message, MessageBox } from "element-ui";
import router from "./router";
import store from "./store";
axios.defaults.baseURL = process.env.VUE_APP_FELO_API_URL;
const request = axios.create({
  timeout: 50000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
request.interceptors.request.use((config) => {
  config.headers["Authorization"] = localStorage.getItem("token"); // 请求头带上token
  return config;
});
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log("wwwww", res);
    if (res.code !== 200 && res.code !== "OK") {
      Message({
        message: res.message,
        type: "error",
        duration: 5 * 1000,
      });
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 401 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm(
          "你已被登出，可以取消继续留在该页面，或者重新登录",
          "确定登出",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("FedLogOut").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      }
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    if (error.response) {
      console.log(error.response); // 请求已发出，但服务器响应的状态码不在 2xx 范围内
      Message({
        message: error.response.data.message,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error.response.data);
    }
  }
);
export default request;
